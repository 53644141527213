export interface GDPRinfo {
  name: string;
  email: string;
  country: string;
  mowers?: Mower[];
}

export interface Mower {
  name: string;
  centralPosition?: LongLat;
  positions?: LongLat[];
}

export interface LongLat {
  longitude: number;
  latitude: number;
}

export interface MowerEnvironmentRequest {
  mowerId: string;
  newEnvironment: string;
  requestedBy: string;
}
export interface MowerGatewayDebuggingRequest {
  deviceId: string;
}

export interface AllowedInLiveResponse {
  isAllowed: boolean;
}

export function isAllowedInLiveResponse(obj: unknown): obj is AllowedInLiveResponse {
  return typeof (obj as AllowedInLiveResponse).isAllowed === 'boolean';
}

export interface StartedAtTimestampResult {
  startedAtTimestamp: number;
}

export function isStartedAtTimestampResult(obj: unknown): obj is StartedAtTimestampResult {
  return typeof (obj as StartedAtTimestampResult).startedAtTimestamp === 'number';
}
export interface TwinExistsResponse {
  twinExists: boolean;
}

export interface SuccessResponse {
  response: string;
}
