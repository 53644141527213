import styled from 'styled-components';

export const StyleMowerGatewayDebuggingPresentation = styled.pre`
  h2 {
    color: red;
  }
  h3 {
    color: green;
  }
`;
