export const tools = [
  'Info',
  'Mower Status',
  'Fetch Twin',
  'Delete Twin',
  'GDPR Report',
  'Map Files',
  'Backend Pairing',
  'Remote Debugging',
  'Mower Gateway Debugging',
  'Mower Environment',
];

export const roles = {
  Unauthorized: 'Unauthorized',
  Readonly: 'Readonly',
  User: 'User',
  Support: 'Support',
  Admin: 'Admin',
};
